#cf-turnstile {
    margin: 0 auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.Email-Signup-div {
  text-align: center;
}

.EmailSignupForm-button {
  align-items: center;
  background-color: rgb(3, 129, 39);
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  padding: 0.3rem 0.3rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
}

.EmailSignupForm-button:hover, .EmailSignupForm-button:focus {
  background-color: rgba(3, 129, 39, 0.9);
}


.EmailSignupForm-button:disabled {
  background-color: rgba(3, 129, 39, 0.5);
}