.List-div {
  text-align: center;
  width: 95%;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 1rem;
  border: 4px solid #aaa;
}

.Markdown {
  text-align: left;
}

.Share-div {
  text-align: center;
}

.Share-buttons-div {
    justify-content: center;
    display: flex;
    column-gap: 1rem;
    margin-top: 0.5rem;
}

.Share-button {
  align-items: center;
  background-color: rgb(40, 44, 52);
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  justify-content: center;
  line-height: 1;
  padding: 0.3rem 0.3rem;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  border: 0;
  --wc-stm-link-text-decoration: none;
  --wc-stm-link-color-initial: white;
  --wc-stm-link-color-active: white;
  --wc-stm-form-submit-background-color: #eee;
  --wc-stm-form-submit-color: #fff;
  --wc-stm-form-button-background-color-hover: rgba(40, 44, 52, 0.9); 
  --wc-stm-form-button-color-hover: #fff;
  --wc-stm-dialog-border-radius: 0;
  --wc-stm-form-input-border-radius: 0;
  --wc-stm-form-button-border-radius: 0;
}

.Share-button:hover, .Share-button:focus {
  background-color: rgba(40, 44, 52, 0.9);
}