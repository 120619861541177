.ListEmbedding-div {
  text-align: center;
  padding: 1rem;
  border: 4px solid #aaa;
}

.ListEmbedding-h2 {
  color: black; /* Inherit color from parent element */
}

.ListEmbedding-Link {
  text-decoration: none;
}