.ListPreview-div {
  text-align: center;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 4px solid #aaa;
}

.ListPreview-h2 {
  color: black; /* Inherit color from parent element */
}

.ListPreview-Link {
  text-decoration: none;
}