header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: white;
}

.HomeLink {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent element */
}