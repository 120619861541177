footer {
  font-size: 1rem;
  text-align: center;
}

.FooterLink {
    color: inherit; /* Inherit color from parent element */
}

.HomeLink {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit color from parent element */
}