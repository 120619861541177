.LegalNotice-div {
    width: 95%;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
    padding: 1rem;
    border: 4px solid #aaa;
}

.LegalNotice-content-div {
    text-align: left;
    display: inline-block;
}